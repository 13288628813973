'use client';
import ModuleCard from '@/components/dashboard/ModuleCard';
import { userPermissionsAtom } from '@/context/atoms/UserPermissions';
import { type Module, useAppModules } from '@/context/modules';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

export default function ModuleList(): React.JSX.Element {
  const [anyNodePermissions] = useAtom(userPermissionsAtom);
  const { getAccessibleModules } = useAppModules();

  return (
    <Grid container spacing={2}>
      {getAccessibleModules(anyNodePermissions).map((m: Module, i: number) => {
        return <ModuleCard appModule={m} timeout={650 * i} key={i} />;
      })}
    </Grid>
  );
}
