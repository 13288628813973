'use client';
import StyledAvatar from '@/components/common/styled/StyledAvatar';
import StyledCard from '@/components/common/styled/StyledCard';
import { useThemeBreakpointResolve } from '@/components/useThemeBreakpointResolve';
import { type Module } from '@/context/modules';
import { createLinearGradient } from '@/helper/utilities';
import { CardActionArea, CardHeader, Grid, Grow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useRouter } from 'next/navigation';
import React from 'react';

export default function ModuleCard({ appModule, timeout }: { appModule: Module; timeout: number }): React.JSX.Element {
  const router = useRouter();
  const { title, href, icon: Icon, bgImage } = appModule;
  const themeBreakPointResolve = useThemeBreakpointResolve();

  const handleUseClick = (): void => {
    router.push(href);
  };

  const avatarSize = themeBreakPointResolve({
    xs: 40,
    lg: 50,
    xl: 60,
  });

  const gradient = createLinearGradient(150, [
    { color: { r: 67, g: 67, b: 67, a: 1 }, position: 0 },
    { color: { r: 67, g: 67, b: 67, a: 1 }, position: 40 },
    { color: { r: 67, g: 67, b: 67, a: 0.93 }, position: 57 },
    { color: { r: 67, g: 67, b: 67, a: 0.75 }, position: 75 },
    { color: { r: 67, g: 67, b: 67, a: 0 }, position: 100 },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const gradientAlt = createLinearGradient(90, [
    { color: { r: 67, g: 67, b: 67, a: 1 }, position: 0 },
    { color: { r: 67, g: 67, b: 67, a: 1 }, position: 25 },
    { color: { r: 67, g: 67, b: 67, a: 0.93 }, position: 57 },
    { color: { r: 67, g: 67, b: 67, a: 0.75 }, position: 75 },
    { color: { r: 67, g: 67, b: 67, a: 0 }, position: 100 },
  ]);

  return (
    <Grid item xs={12} md={6}>
      <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout }}>
        <Paper elevation={4}>
          <StyledCard
            elevation={0}
            onClick={handleUseClick}
            sx={{
              backgroundImage: `url(${bgImage.src});`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              boxShadow: 'inset 14px 0px 8px -4px black',
            }}
          >
            <CardActionArea
              sx={{
                p: themeBreakPointResolve({
                  xs: 0,
                  sm: 2,
                  md: 4,
                  lg: 6,
                  xl: 10,
                }),
                background: gradient,
              }}
            >
              <CardHeader
                avatar={
                  <StyledAvatar
                    variant="rounded"
                    sx={{
                      width: avatarSize,
                      height: avatarSize,
                    }}
                  >
                    <Icon
                      sx={{
                        fontSize: avatarSize,
                      }}
                    />
                  </StyledAvatar>
                }
                title={title}
                titleTypographyProps={{
                  fontSize: themeBreakPointResolve({
                    xs: '22pt',
                    lg: '28pt',
                    xl: '34pt',
                  }),
                }}
              />
            </CardActionArea>
          </StyledCard>
        </Paper>
      </Grow>
    </Grid>
  );
}

// Alternative Card Placement:
//
// <Grid
//       item
//       xs={12}
//       sx={{
//         marginX: themeBreakPointResolve({
//           sm: 0,
//           md: '14vw',
//           lg: '26vw',
//         }),
//       }}
//     >
