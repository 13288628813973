export const getLastParts = (coordinate: string, parts: number): string => {
  if (parts <= 0) {
    console.log('Number of parts must be greater than 0.');
    return '';
  }

  const regex = /([_\-.])/;
  const partsArray = coordinate.split(regex).filter((part) => part !== '');

  const partsOnly = partsArray.filter((_, i) => i % 2 === 0);
  const delimiters = partsArray.filter((_, i) => i % 2 === 1);

  if (parts >= partsOnly.length) {
    return coordinate;
  } else if (parts === 1) {
    return partsOnly[partsOnly.length - 1];
  } else {
    const start = partsOnly.length - parts;
    let result = partsOnly.slice(start).join('');

    for (let i = start; i < partsOnly.length - 1; i++) {
      result = result.replace(partsOnly[i], partsOnly[i] + delimiters[i]);
    }

    return result;
  }
};

// @TODO careful, there is no check if coordinateA is a prefix of coordinateB
export const subCoordinate = (coordinateA: string, coordinateB: string): string => {
  const partCount = coordinateA.split(/[_\-.]/).filter((part) => part !== '').length;
  const partsArrayCurrentCoordinate = coordinateB.split(/[_\-.]/).filter((part) => part !== '');
  const partCountCurrentCoordinate = partsArrayCurrentCoordinate.length;
  const partLengthAreaToCurrent = partCountCurrentCoordinate - partCount;
  const partLength = partLengthAreaToCurrent > 0 ? partLengthAreaToCurrent : 1;
  return getLastParts(coordinateB, partLength);
};

export interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface ColorStop {
  color: Color;
  position: number;
}

export const colorToRgba = (color: Color): string => {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
};

export const createLinearGradient = (angle: number, colorStops: ColorStop[]): string => {
  const gradientStops = colorStops.map((stop) => `${colorToRgba(stop.color)} ${stop.position}%`).join(', ');
  return `linear-gradient(${angle}deg, ${gradientStops})`;
};
